






















































import useAlert from '@/use/alert'
import { computed, defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    }
  },

  setup(props, { root }) {
    const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

    const alerts = ref<any[]>([])

    const {
      alertData,
      getAlertType,
      getAlertTypeIcon,
      getAlertTimeLeft,
      getAlertMileageLeft,
      getAlertStatus,
      getAlertStatusColor,
      getAlertEndDate,
      getAlertEndDateColor,
      getAlertEndMileageColor,
      getAlertEndMileage,
      getAlertVehicle,
      getAlertCompany,
    } = useAlert()

    const state = reactive({
      loading: false,
      headers: [
        { value: 'type', text: 'Typ', sortable: false },
        { value: 'date', text: 'Data wyświetlenia alertu', sortable: false },
        { value: 'mileage', text: 'Przebieg wyświetlenia alertu', sortable: false },
        { value: 'endDate', text: 'Termin wykonania działania', sortable: false },
        { value: 'endMileage', text: 'Przebieg wykonania działania', sortable: false },
        { value: 'status', text: 'Status', sortable: false },
      ],
      items: computed(() => alerts.value.map(alert => ({ ...alert }))),
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false
      },
      total: 0,
    })

    const fetch = () => {
      state.loading = true

      axiosInstance
        .get(`vehicle/${props.id}/alert`)
        .then(({ data }) => {
          alerts.value = data.alerts
          state.total = data.total
        })
        .catch(() => {
          alerts.value = []
          state.total = 0
        })
        .finally(() => state.loading = false)
    }

    onMounted(fetch)

    const onRowClick = (event: MouseEvent, data: any) => {
      root.$router.push({ name: 'panel.alert.view', params: { id: data.item.id } })
    }

    return {
      alertData,
      getAlertType,
      getAlertTypeIcon,
      getAlertTimeLeft,
      getAlertMileageLeft,
      getAlertStatus,
      getAlertStatusColor,
      getAlertEndDate,
      getAlertEndDateColor,
      getAlertEndMileage,
      getAlertEndMileageColor,
      getAlertVehicle,
      getAlertCompany,
      
      state,

      onRowClick
    }
  }
})
