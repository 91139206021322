import { render, staticRenderFns } from "./o-vehicle-alert.vue?vue&type=template&id=8e07d8d0&lang=pug&"
import script from "./o-vehicle-alert.vue?vue&type=script&lang=ts&"
export * from "./o-vehicle-alert.vue?vue&type=script&lang=ts&"
import style0 from "./o-vehicle-alert.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBadge,VChip,VDataTable,VDivider,VIcon,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
