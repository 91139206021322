var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-vehicle-alert py-4"},[_c('v-data-table',{attrs:{"loading":_vm.state.loading,"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"item-key":"id","mobile-breakpoint":"1904"},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertType(item.type))?_c('span',{staticClass:"text-no-wrap"},[_c('v-badge',{attrs:{"dot":"","color":"red","overlap":"","left":"","value":item.status === 'to_do'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.getAlertTypeIcon(item.type)))])],1),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.getAlertType(item.type)))])],1):_c('span',{staticClass:"text-no-wrap"},[_c('v-badge',{attrs:{"dot":"","color":"red","overlap":"","left":"","value":item.status === 'to_do'}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")])],1),_c('span',{staticClass:"ml-2"},[_vm._v("Inny")])],1)]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.date)?_c('v-list-item',{staticClass:"pa-0",attrs:{"dense":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm._f("moment")(item.date,'from', 'now')))]),_c('v-list-item-subtitle',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm._f("moment")(item.date,'DD.MM.YYYY')))])],1)],1):_c('v-divider')]}},{key:"item.mileage",fn:function(ref){
var item = ref.item;
return [(typeof item.mileage === 'number')?_c('span',{staticClass:"text-no-wrap grey--text"},[_vm._v(_vm._s(item.mileage.toLocaleString())+" km")]):_c('v-divider')]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertEndDate(item))?_c('span',{staticClass:"d-flex align-center"},[_c('v-chip',{staticClass:"mr-4",attrs:{"small":"","color":_vm.getAlertEndDateColor(item)}},[_vm._v(_vm._s(_vm._f("moment")(_vm.getAlertEndDate(item),'DD.MM.YYYY')))]),_c('span',{staticClass:"d-flex align-center text-no-wrap"},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","color":_vm.getAlertEndDateColor(item)}},[_vm._v("mdi-alert")]),_c('span',{class:((_vm.getAlertEndDateColor(item)) + "--text")},[_vm._v(_vm._s(_vm.getAlertTimeLeft(item)))])],1)],1):_c('v-divider')]}},{key:"item.endMileage",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertEndMileage(item))?_c('span',{staticClass:"text-no-wrap d-flex align-center"},[_c('v-chip',{staticClass:"mr-4",attrs:{"small":"","color":_vm.getAlertEndMileageColor(item)}},[_vm._v(_vm._s(_vm.getAlertEndMileage(item))+" km")]),_c('span',{staticClass:"d-flex align-center text-no-wrap"},[_c('v-icon',{staticClass:"mr-1",attrs:{"left":"","color":_vm.getAlertEndMileageColor(item)}},[_vm._v("mdi-alert")]),_c('span',{class:((_vm.getAlertEndMileageColor(item)) + "--text")},[_vm._v(_vm._s(_vm.getAlertMileageLeft(item))+" km")])],1)],1):_c('v-divider')]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.getAlertStatus(item.status))?_c('span',[_c('v-chip',{attrs:{"small":"","color":_vm.getAlertStatusColor(item.status)}},[_vm._v(_vm._s(_vm.getAlertStatus(item.status)))])],1):_c('v-divider')]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }